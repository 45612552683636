import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../molecules/Section'
import Button from '../atoms/Button'

const query = graphql`
  query SectionFundQuery {
    fund: allFile(filter: { relativePath: { eq: "sections/fund.json" } }) {
      edges {
        node {
          childSectionsJson {
            title
            description
            action {
              title
              url
            }
          }
        }
      }
    }
  }
`

export default function SectionFund() {
  const data = useStaticQuery(query)
  const {
    title,
    description,
    action
  } = data.fund.edges[0].node.childSectionsJson

  return (
    <Section title={title} description={description}>
      <Button style="primary" to={action.url}>
        {action.title}
      </Button>
    </Section>
  )
}
