import React from 'react'
import shortid from 'shortid'
import transformEventDate from './transformEventDate'
import styles from './EventsArchive.module.css'
import { EventItem } from './Event'

function pastEvents(events: { node: EventItem }[]) {
  if (events.length > 0) {
    const collator = new Intl.Collator('en', {
      numeric: true,
      sensitivity: 'base'
    })

    const past = new Date()
    past.setDate(past.getDate() - 3)

    const eventsFilteredSorted = events
      .filter(({ node }: { node: EventItem }) => {
        const eventDate = new Date(node.date)

        return eventDate <= past
      })
      .sort((a: { node: EventItem }, b: { node: EventItem }) =>
        collator.compare(b.node.date, a.node.date)
      )

    return eventsFilteredSorted
  } else {
    return []
  }
}

function PastEvent({ event }: { event: EventItem }) {
  return (
    <a
      href={event.link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.eventRow}
    >
      <p className="date">
        {transformEventDate(
          event?.date?.replace(/-/g, '/'),
          event?.date_end?.replace(/-/g, '/')
        )}
      </p>
      <p className={styles.city}>{event.city}</p>
      <p className={styles.event}>{event.eventName}</p>
    </a>
  )
}

export default function EventsArchive({
  events
}: {
  events: { node: EventItem }[]
}) {
  const filteredEvents = pastEvents(events)

  return (
    <div className={styles.overflowDiv}>
      {filteredEvents.map(({ node }: { node: EventItem }) => (
        <PastEvent event={node} key={shortid.generate()} />
      ))}
    </div>
  )
}
