import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '../../molecules/Section'
import EventsList from './EventsList'
import Modal from '../../atoms/Modal'
import EventsArchive from './EventsArchive'

const query = graphql`
  query SectionEventsQuery {
    allEventsJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          city
          eventName
          date
          date_end
          link
        }
      }
    }
  }
`

export default function SectionEvents() {
  const data = useStaticQuery(query)
  const events = data.allEventsJson.edges
  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleToggleModal() {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <Section
      id="events"
      title="Events"
      description="Meet Ocean team members at the following events."
    >
      <EventsList events={events} toggleModal={handleToggleModal} />
      <Modal
        title="Events archive"
        isOpen={isModalOpen}
        onToggleModal={handleToggleModal}
      >
        <EventsArchive events={events} />
      </Modal>
    </Section>
  )
}
