import React, { useState, useEffect } from 'react'
import axios, { CancelTokenSource } from 'axios'
import NumberUnit from '../../molecules/NumberUnit'
import { SocialIcon } from '../../atoms/SocialIcon'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import { formatNum } from '../../../utils'
import styles from './CommunityNumbers.module.css'

interface CommunityNumber {
  key: string
  title: string
  tooltip: string
}

const url = 'https://oceanprotocol-community.now.sh'

async function fetchNumbers(signal: CancelTokenSource) {
  const response = await axios(url, {
    cancelToken: signal.token
  })

  const github = formatNum(response.data.github.stars)
  const blog = formatNum(response.data.medium.followers)
  const twitter = formatNum(response.data.twitter.followers)
  const telegram = formatNum(
    response.data.telegram.community + response.data.telegram.news
  )
  const discord = formatNum(response.data.discord.members)

  return { twitter, blog, github, discord, telegram }
}

export default function CommunityNumbers({
  items
}: {
  items: CommunityNumber[]
}) {
  const { social } = useSiteMetadata()
  const [numbers, setNumbers] = useState({
    twitter: '----',
    telegram: '----',
    blog: '----',
    github: '----',
    discord: '---'
  })
  const signal = axios.CancelToken.source()

  useEffect(() => {
    async function init() {
      try {
        const numbers = await fetchNumbers(signal)
        setNumbers(numbers)
      } catch (error) {
        console.error(error.message)
      }
    }
    init()

    return () => {
      signal.cancel()
    }
  }, [])

  return (
    <ul className={styles.communityNumbers}>
      {items.map((item: CommunityNumber) => {
        const autoLink = social.filter(
          (network: { name: string; url: string }) =>
            network.name.toLowerCase() === item.key
        )[0]

        return (
          <NumberUnit
            label={item.title}
            value={(numbers as { [key: string]: string })[item.key]}
            key={item.key}
            link={autoLink?.url}
            linkTooltip={item.tooltip}
            icon={<SocialIcon icon={item.key} />}
          />
        )
      })}
    </ul>
  )
}
