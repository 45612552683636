import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import CommunityNumbers from '../components/pages/community/CommunityNumbers'
import PageCommunity from '../components/pages/community'

export default function PageGatsbyCommunity(props: PageProps) {
  const {
    title,
    description,
    actions,
    communityNumbers
  } = (props.data as any).hero.edges[0].node.childCommunityJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroCenter
      heroActions={actions}
      heroContent={<CommunityNumbers items={communityNumbers} />}
    >
      <PageCommunity />
    </Layout>
  )
}

export const contentQuery = graphql`
  query CommunityPageQuery {
    hero: allFile(
      filter: { relativePath: { eq: "pages/community/hero.json" } }
    ) {
      edges {
        node {
          childCommunityJson {
            title
            description
            actions {
              title
              url
            }
            communityNumbers {
              key
              title
              tooltip
            }
          }
        }
      }
    }
  }
`
