import React from 'react'
import shortid from 'shortid'
import Event, { EventItem } from './Event'
import styles from './EventsList.module.css'

const FilteredEvents = ({ events }: { events: { node: EventItem }[] }) => {
  if (!events.length) return null

  // first, filter out all past events
  const eventsFiltered = events.filter(({ node }) => {
    const now = new Date()
    const past = now.setDate(now.getDate() - 3) // 3 days ago from right now
    const eventDate = new Date(node.date).getTime()

    return eventDate > past
  })

  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  // then, sort the remaining ones by date
  const eventsFilteredSorted = eventsFiltered.sort((a, b) =>
    collator.compare(a.node.date, b.node.date)
  )

  return (
    <>
      {eventsFilteredSorted.map(({ node }) => (
        <li className={styles.item} key={shortid.generate()}>
          <Event event={node} box />
        </li>
      ))}
    </>
  )
}

const EventsList = ({
  events,
  toggleModal
}: {
  events: { node: EventItem }[]
  toggleModal?: () => void
}) => (
  <ul className={styles.list}>
    <FilteredEvents events={events} />
    <li className={styles.last} onClick={() => toggleModal()}>
      View events archive
    </li>
  </ul>
)

export default EventsList
