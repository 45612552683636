import React from 'react'
import Button from '../../atoms/Button'
import Section from '../../molecules/Section'
import { markdownify } from '../../../utils'
import styles from '../community/SectionAmbassador.module.css'
import { CheckList } from '../../atoms/Lists'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query SectionAmbassadorsQuery {
    ambassadors: allFile(
      filter: { relativePath: { eq: "pages/community/ambassadors.json" } }
    ) {
      edges {
        node {
          childCommunityJson {
            title
            description
            text
            list
            button {
              title
              url
            }
          }
        }
      }
    }
  }
`

export default function SectionAmbassadors() {
  const data = useStaticQuery(query)
  const content = data.ambassadors.edges[0].node.childCommunityJson

  return (
    <Section
      title={content.title}
      description={content.description}
      id="ambassadors"
      className={styles.section}
      headerCenter
    >
      <div className={styles.grid}>
        <div>{markdownify(content.text)}</div>

        <CheckList items={content.list} />
      </div>
      <div className={styles.actions}>
        <Button href={content.button.url} style="primary">
          {content.button.title}
        </Button>
      </div>
    </Section>
  )
}
