import React from 'react'
import SectionEvents from '../../organisms/SectionEvents'
import SectionAmbassadors from './SectionAmbassadors'
import SectionFund from '../../organisms/SectionFund'
import ActionBar from '../../molecules/ActionBar'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query PageCommunityQuery {
    actions: allFile(
      filter: { relativePath: { eq: "pages/community/actions.json" } }
    ) {
      edges {
        node {
          childCommunityJson {
            primary {
              title
              description
              url
            }
            secondary {
              title
              description
              url
            }
          }
        }
      }
    }
  }
`

export default function PageCommunity() {
  const data = useStaticQuery(query)
  const { primary, secondary } = data.actions.edges[0].node.childCommunityJson

  return (
    <>
      <SectionEvents />
      <SectionAmbassadors />
      <SectionFund />
      <ActionBar actions={primary} />
      <ActionBar actions={[...secondary]} />
    </>
  )
}
